import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getStockData = createAsyncThunk(
  "getStockData",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

const stockSlice = createSlice({
  name: "stockSlice",
  initialState: {
    loading: false,
    allStock: null,
    totalPage: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getStockData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStockData.fulfilled, (state, action) => {
        state.loading = false;       
        state.allStock = action?.payload;
        state.totalPage = action?.payload?.results?.total_pages;
      })
      .addCase(getStockData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default stockSlice.reducer;

import React from "react";
import {
  Box,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MrButton from "Components/MrButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { agentsDetail } from "DummyData";
// import { useNavigate } from "react-router-dom";

const AllAgentsPage = () => {
  // const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          // mt: "2rem",
          // border: "1px solid red",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "1rem",
            // border:"1px solid green"
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            sx={{
              fontSize: "1.2rem",
            }}
          >
            All Agents
          </Typography>

          {/* <MrButton
          variant={"text"}
          disableRipple={"disableRipple"}
          endIcon={<ArrowForwardIcon />}
          hoverEffect={"transparent"}
          color={"#000"}
          fontSize={".9rem"}
        >
          view all agents
        </MrButton> */}

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: { xs: "70%", sm: "43.875rem", md: "43.875rem" },
              backgroundColor: "#fafaff",
              boxShadow: "0px 10px 20px 0px rgba(174, 174, 174, 0.15)",
              "& .MuiInputBase-root": {
                backgroundColor: "white.main",
                height: "3rem",
                borderRadius: ".625rem",
              },
              "&.MuiFormControl-root": {
                backgroundColor: "#fafaff",
                height: "3rem",
                borderRadius: ".625rem",
              },
              mb: "1rem",
            }}
            placeholder="Search by name"
            name="search"
            // value={search}
            // onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
          />
        </Box>

        {/* Table Start from here */}

        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "1px solid #DADADA",
            borderRadius: "0.625rem",
            overflow: "auto",
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
                borderLeft: "1px solid #DADADA",
                borderRight: "1px solid #DADADA",
              },
              "& .MuiTableCell-root:first-child": {
                borderLeft: 0,
              },
              "& .MuiTableCell-root:last-child": {
                borderRight: 0,
              },
              minWidth: 700,
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#F7F4F8",
                borderRadius: "2rem",
                overflow: "hidden",
                position: "sticky",
                top: "0rem",
                height: "3rem",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  S.No
                </TableCell>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  Name Of Company
                </TableCell>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  Agent Details
                </TableCell>

                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  Added on
                </TableCell>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  Total Orders
                </TableCell>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  Last Sale
                </TableCell>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  View
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                backgroundColor: "white.main",
                boxShadow: "none",
                "& tr:nth-of-type(even)": {
                  backgroundColor: "#fcfcfc",
                },
              }}
            >
              {agentsDetail &&
                agentsDetail?.length > 0 &&
                agentsDetail?.slice(0, 5)?.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      border: "none",
                      height: "4.25rem",
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {i < 9 ? `0${i + 1}` : `${i + 1}`}
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "0.8rem",
                          fontWeight: "400",
                          textDecoration: "underline",
                          minWidth: "6rem",
                        }}
                      >
                        {row?.nameOfCompany || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: ".2rem",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: "400",
                            color: "#1C1B1F",
                          }}
                        >
                          {row?.name || "-"}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: "400",
                            color: "#606060",
                          }}
                        >
                          {row?.email || "-"}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        sx={{ fontSize: ".8rem", width: "7rem" }}
                      >
                        {row?.dateAndTime || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ width: "8rem" }}>
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        sx={{ fontSize: ".8rem" }}
                      >
                        {row?.totalOrder || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        sx={{
                          fontSize: ".8rem",
                          backgroundColor: row?.lastSale?.includes("weeks")
                            ? "#FFE0E0"
                            : row?.lastSale?.includes("hours")
                            ? "#E3FFE0"
                            : "#FFF3E0",
                          color: row?.lastSale?.includes("weeks")
                            ? "danger.main"
                            : row?.lastSale?.includes("hours")
                            ? "success.main"
                            : "warning.main",
                          p: "0.5rem 0.75rem",
                          borderRadius: ".5rem",
                          wordBreak: "break-word",
                          width: "6rem",
                        }}
                      >
                        {row?.lastSale || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ width: "8rem" }}>
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        sx={{ fontSize: ".8rem" }}
                      >
                        {row?.category || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ width: "8rem" }}>
                      <MrButton
                        // onClick={() => navigate("/agent-details")}
                        fontSize={".7rem"}
                      >
                        View Details
                      </MrButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AllAgentsPage;

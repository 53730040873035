import { Box, Typography } from "@mui/material";
// import { MrPrice } from "Utils";
import React from "react";
import PropTypes from "prop-types";

const AnalyticsCard = ({
  ele,
  i,
  totalRevenue,
  totalSimAmmount,
  totalSimSold,
  countriesReached,
}) => {
  // console.log(totalRevenue, "Total revenwe");
  return (
    <>
      <Box
        sx={{
          height: "5.75rem",
          width: "100%",
          backgroundColor: "white.main",
          borderRadius: "0.9375rem",
          overflow: "hidden",
          boxShadow: "0px 10px 20px 0px rgba(174, 174, 174, 0.15)",
          p: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
            // border: "1px solid green",
          }}
        >
          <Box
            sx={{
              height: "3.75rem",
              width: "35%",
            }}
          >
            <img
              src={ele?.image}
              alt="No Preview"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                mixBlendMode: "multiply",
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              //   border: "1px solid red",
              height: "3.75rem",
              width: "75%",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{
                fontSize: "1.375rem",
                color:
                  i === 0
                    ? "#34528b"
                    : i === 1
                    ? "#4ea224"
                    : i === 2
                    ? "#d86546"
                    : "#625dfd",
              }}
            >
              {i === 0
                ? `₹${totalRevenue?.toFixed(2) || 0}`
                : i === 1
                ? `₹${totalSimAmmount?.toFixed(2) || 0}`
                : i === 2
                ? `${totalSimSold || 0} `
                : countriesReached?.total || 1}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{ fontSize: "0.850rem", color: "primaryText.main" }}
            >
              {ele?.title || "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

AnalyticsCard.propTypes = {
  ele: PropTypes.object,
  i: PropTypes.number,
  totalRevenue: PropTypes.any,
  totalSimAmmount: PropTypes.any,
  totalSimSold: PropTypes.any,
  countriesReached: PropTypes.any,
};

export default AnalyticsCard;

import { Box, Typography } from "@mui/material";
import React from "react";
import LoginImg from "Assets/Images/Hands.webp";

const LoginPageImage = (flexDirection) => {
  return (
    <>
      <Box
        flexDirection={flexDirection}
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          backgroundColor: "#FAFAFF",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "2.25rem", fontWeight: "600" }}
          >
            Empower Your Business with
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "2.25rem", fontWeight: "700" }}
          >
            Global Connectivity Solutions
          </Typography>
        </Box>
        <Box
          sx={{
            height: "24.188rem",
            borderRadius: "1.5rem",
            overflow: "hidden",
          }}
        >
          <img
            src={LoginImg}
            alt="No Preview"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../../Assets/Images/no-preview.jpeg");
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default LoginPageImage;

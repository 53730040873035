import { Typography } from "@mui/material";
import React from "react";

const AgentDetailPage = () => {
  return (
    <>
      <Typography>Agent Detail Page</Typography>
    </>
  );
};

export default AgentDetailPage;

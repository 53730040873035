import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalPopUp = ({
  children,
  open,
  onClose,
  title,
  width,
  height,
  p,
  showHeader,
  justifyContent,
}) => {
  return (
    <Modal
      keepMounted
      open={open}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: width, md: width },
          height: height,
          borderRadius: "1rem",
          border: "none",
          bgcolor: "background.paper",
          boxShadow: 24,
          overflowY: "auto",
          // p: "1rem 1rem",
          // "&::-webkit-scrollbar": {
          //   display: "none",
          // },
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        >
          {showHeader ? (
            <Box
              sx={{
                position: "sticky",
                top: 0,
                left: 0,
                right: 0,
                height: "4.1875rem",
                borderBottom: "1.5px solid #dadada",
                display: "flex",
                justifyContent: justifyContent,
                alignItems: "center",
                zIndex: 2,
                backgroundColor: "white.main",
              }}
            >
              <IconButton
                onClick={onClose}
                sx={{
                  position: "absolute",
                  top: ".5rem",
                  right: ".5rem",
                  zIndex: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: ".75rem",
                }}
              >
                <Typography
                  ml={"1rem"}
                  variant="h4"
                  fontWeight={400}
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  {title}
                </Typography>
              </Box>
            </Box>
          ) : null}

          <Box
            p={p}
            sx={{
              overflowY: "auto",
              height: showHeader ? `calc(100% - 4.1875rem)` : "100%",
              // "&::-webkit-scrollbar": {
              //   display: "none",
              // },
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

ModalPopUp.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  p: PropTypes.string,
  showHeader: PropTypes.bool.isRequired,
  justifyContent: PropTypes.string,
};

export default ModalPopUp;

import { createSlice } from "@reduxjs/toolkit";

const countryPlansSlice = createSlice({
  name: "countryPlansSlice",
  initialState: {
    loading: false,
    singlePlan: null,
  },

  reducers: {
    handleSinglePlan: (state, action) => {
      state.singlePlan = action.payload;
    },
    handlePhysicalPlan: (state, action) => {
      state.singlePlan = action.payload;
    },
    handleRegionalPlan: (state, action) => {
      state.singlePlan = action.payload;
    },
    handleDataCallVoicePlan: (state, action) => {
      state.singlePlan = action.payload;
    },
    handleDataPlan: (state, action) => {
      state.singlePlan = action.payload;
    },  
  },
});

export const {
  handleDataPlan,
  handleSinglePlan,
  handlePhysicalPlan,
  handleRegionalPlan,
  handleDataCallVoicePlan,
} = countryPlansSlice.actions;
export default countryPlansSlice.reducer;

import React, { useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { stockTable } from "DummyData";
import { allStock } from "DummyData";

const SimStock = () => {
  const [stockType, setStockType] = useState("physicalInternationalSimStock");
  const [searchByIccid, setSearchByIccid] = useState("");
  const [imsiId, setImsiId] = useState("");
  const [page, setPage] = useState(1);
  console.log(page, "Page");
  const [activePage, setActivePage] = useState(1);
  const handleChange = (event) => {
    setImsiId(event.target.value);
  };
  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: "3rem", mb: "2rem" }}
      >
        <Box
          onClick={() => setStockType("physicalInternationalSimStock")}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor:
              stockType === "physicalInternationalSimStock"
                ? "#fde9ff"
                : "transparent",
            height: "4rem",
            borderRadius: ".625rem",
            overflow: "hidden",
            width: "15rem",
            cursor: "pointer",
            transition: ".3s",
            "&:hover": {
              backgroundColor: "#fde9ff",
            },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={400}
            sx={{ fontSize: ".9rem", color: "#606060" }}
          >
            eSim Int&apos;l Sim Stock
          </Typography>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ fontSize: ".9rem", color: "success.main" }}
          >
            44 SIM card
          </Typography>
        </Box>
        <Box
          onClick={() => setStockType("indiaSimStock")}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor:
              stockType === "indiaSimStock" ? "#fde9ff" : "transparent",
            height: "4rem",
            borderRadius: ".625rem",
            overflow: "hidden",
            width: "15rem",
            cursor: "pointer",
            transition: ".3s",
            "&:hover": {
              backgroundColor: "#fde9ff",
            },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={400}
            sx={{ fontSize: ".9rem", color: "#606060" }}
          >
            Physical Int&apos;l Sim Stock
          </Typography>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ fontSize: ".9rem", color: "success.main" }}
          >
            66 SIM card
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: { xs: "100%", sm: "30.875rem", md: "30.875rem" },
            backgroundColor: "#fafaff",
            boxShadow: "0px 10px 20px 0px rgba(174, 174, 174, 0.15)",
            "& .MuiInputBase-root": {
              backgroundColor: "white.main",
              height: "3rem",
              borderRadius: ".625rem",
            },
            "&.MuiFormControl-root": {
              backgroundColor: "#fafaff",
              height: "3rem",
              borderRadius: ".625rem",
            },
          }}
          placeholder="Search by ICCID"
          name="searchByIccid"
          value={searchByIccid}
          onChange={(e) => {
            setSearchByIccid(e.target.value);
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="body"
            fontWeight={400}
            sx={{ fontSize: ".8rem", mb: ".5rem", mt: "-1rem" }}
          >
            Filter by IMSI ID
          </Typography>
          <FormControl
            sx={{
              minWidth: "10rem",
              height: "3rem",
              borderRadius: ".625rem", // Border-radius at FormControl level
              overflow: "hidden", // Ensures borderRadius applies visually
            }}
          >
            <Select
              value={imsiId}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "3rem",
                borderRadius: ".625rem", // Apply borderRadius to Select
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: ".625rem", // Ensures the dropdown has rounded corners
                },
                ".MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  height: "100%", // Ensures height fills FormControl
                },
              }}
            >
              <MenuItem value="">All</MenuItem>
              {allStock &&
                allStock?.length &&
                allStock?.map((ele, i) => (
                  <MenuItem key={i} value={ele}>
                    {`IMSI ID ${ele || "-"}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* containers for stock sims*/}
      <Box
        sx={{
          mt: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        {/* {stockType === "physicalInternationalSimStock" ? (
        <Typography>baba</Typography>
      ) : (
        <Typography>papa</Typography>
      )} */}

        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "1px solid #DADADA",
            borderRadius: "0.625rem",
            overflow: "auto",
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
                borderLeft: "1px solid #DADADA",
                borderRight: "1px solid #DADADA",
              },
              "& .MuiTableCell-root:first-child": {
                borderLeft: 0,
              },
              "& .MuiTableCell-root:last-child": {
                borderRight: 0,
              },
              minWidth: 700,
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#F7F4F8",
                borderRadius: "2rem",
                overflow: "hidden",
                position: "sticky",
                top: "0rem",
                height: "3rem",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  S.No
                </TableCell>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  IMSI ID
                </TableCell>
                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  ICCID
                </TableCell>

                <TableCell
                  sx={{ fontSize: ".875rem", fontWeight: "600" }}
                  align="center"
                >
                  Added on
                </TableCell>
                {/* <TableCell
                sx={{ fontSize: ".875rem", fontWeight: "600" }}
                align="center"
              >
                Total Price
              </TableCell>
              <TableCell
                sx={{ fontSize: ".875rem", fontWeight: "600" }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                sx={{ fontSize: ".875rem", fontWeight: "600" }}
                align="center"
              >
                Action
              </TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                backgroundColor: "white.main",
                boxShadow: "none",
                "& tr:nth-of-type(even)": {
                  backgroundColor: "#fcfcfc",
                },
              }}
            >
              {stockTable &&
                stockTable?.length > 0 &&
                stockTable?.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      border: "none",
                      height: "6.25rem",
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {i < 9 ? `0${i + 1}` : `${i + 1}`}
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          // textDecoration: "underline",
                          minWidth: "10rem",
                        }}
                      >
                        {row?.imsiId || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          color: "#606060",
                        }}
                      >
                        {row?.iccid || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        sx={{
                          fontSize: ".875rem",

                          textTransform: "capitalize",
                          textAlign: "center",
                          // border: "1px solid red",
                        }}
                      >
                        {row?.addedDate || "-"}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      sx={{ fontSize: ".875rem" }}
                    >
                      {localStorage.getItem("current_country") === "india"
                        ? "₹"
                        : "$"}
                      {JSON.parse(row?.product_amount || "0")?.toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      sx={{
                        fontSize: ".875rem",
                        backgroundColor: !row?.is_verified
                          ? "#FFE0E0"
                          : "#E3FFE0",
                        color: !row?.is_verified
                          ? "danger.main"
                          : "success.main",
                        p: "0.5rem 0.75rem",
                        borderRadius: ".5rem",
                        wordBreak: "break-word",
                        width: "11rem",
                      }}
                    >
                      {!row?.is_verified
                        ? "Verification Pending"
                        : "Completed"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <MrButton
                      onClick={() =>
                        navigate(`/order-details/${row?.sim_order_id}`)
                      }
                      endIcon={<ArrowForwardRoundedIcon />}
                      variant={"text"}
                      disableRipple={true}
                      hoverEffect={"transparent"}
                      textDecoration={"underline"}
                      color={"#000"}
                      fontSize={".875rem"}
                    >
                      View
                    </MrButton>
                  </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: {
              xs: "1rem 0rem",
              sm: "initial",
              md: "initial",
            },
          }}
        >
          <Stack spacing={1}>
            <Pagination
              page={activePage}
              count={10}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={(e, value) => {
                setPage(value);
                setActivePage(value);
              }}
              sx={{
                "& .MuiPaginationItem-root": {
                  borderRadius: ".5rem",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "black.main",
                  },
                },
                "& .Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white.main",
                  border: "",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    color: "white.main",
                  },
                },
              }}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default SimStock;

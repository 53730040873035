import { Box, Typography } from "@mui/material";
import React from "react";
import companyLogo from "Assets/Company/sideNavImg.webp";

const LoginHeding = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ height: "5.938rem", width: "5.938rem", mb: ".5rem" }}>
          <img
            src={companyLogo}
            alt="No Preview"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../../Assets/Images/no-preview.jpeg");
            }}
          />
        </Box>

        <Typography
          fontWeight={600}
          variant="h4"
          sx={{ mt: ".75rem", fontSize: "2rem", color: "black.main" }}
        >
          PRUNE PARTNERS
        </Typography>
      </Box>
    </>
  );
};

export default LoginHeding;

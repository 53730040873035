import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createPrunePartner = createAsyncThunk(
  "createPrunePartner",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        // headers: {
        //   authorization: `Token ${localStorage.getItem("token")}`,
        // },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const prunePartnerSlice = createSlice({
  name: "prunePartnerSlice",
  initialState: {
    loading: false,
  },

  reducers: {},
});

export default prunePartnerSlice.reducer;

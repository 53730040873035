import { routes } from "../../Routes";
import React, { useMemo, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const processedRoutes = useMemo(() => {
    if (routes && routes?.length) {
      return routes?.map((ele, i) =>
        ele?.protected && token ? (
          <Route
            key={i}
            path={ele?.path}
            element={ele?.component}
            // title={ele.title}
          />
        ) : !ele?.protected ? (
          <Route
            key={i}
            path={ele?.path}
            element={ele?.component}
            // title={ele?.title}
          />
        ) : null
      );
    }
    return null;
  }, [routes, token]);

  return <Routes>{processedRoutes}</Routes>;
};

export default ProtectedRoutes;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const token = localStorage.getItem("token");

// export const createClient = createAsyncThunk(
//   "createClient",
//   async ({ url, data }) => {
//     try {
//       const response = await axios.post(url, data, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       return response?.data;
//     } catch (error) {
//       return error?.response;
//     }
//   }
// );

// async ({ url, data }) => {
//     try {
//       const response = await http.post(url, data);
//       return response ? response?.data : null;
//     } catch (error) {
//       return error?.response?.data;
//     }
//   }

export const userLogin = createAsyncThunk(
  "userLogin",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        // headers: {
        //   authorization: `Token ${token}`,
        // },
      });
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

// export const userLogin = createAsyncThunk(
//   "userLogin",
//   async ({ url, username, password }) => {
//     try {
//       const basicAuth = btoa(`${username}:${password}`);
//       const response = await axios.post(
//         url,
//         {},
//         {
//           headers: {
//             authorization: `Basic ${basicAuth}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       return response?.data;
//     } catch (error) {
//       return error?.response;
//     }
//   }
// );

// export const getAddressByUserId = createAsyncThunk(
//   "getAddressByUserId",
//   async ({ url }) => {
//     try {
//       const data = await axios.get(url, {
//         headers: {
//           authorization: localStorage.getItem("token"),
//         },
//       });
//       return data ? data?.data : null;
//     } catch (error) {
//       console.log(error);
//       return null;
//     }
//   }
// );

// export const selectAddress = createAsyncThunk("selectAddress", async (data) => {
//   try {
//     return data;
//   } catch (error) {
//     console.log(error, "Something Went Wrong !");
//     return { data: null };
//   }
// });

// export const updateAddress = createAsyncThunk(
//   "updateAddress",
//   async ({ url, data }) => {
//     try {
//       const response = await axios.put(url, data, {
//         headers: {
//           authorization: localStorage.getItem("token"),
//         },
//       });

//       return response?.data;
//     } catch (error) {
//       console.log(error, "Something Went Wrong !");
//       return error?.response?.data;
//     }
//   }
// );

const UserAuthSlice = createSlice({
  name: "UserAuthSlice",
  initialState: {
    loading: false,
    // userFirstName: null,
    // userLastName: null,
  },

  reducers: {},

  // extraReducers: (builder) => {
  // builder;
  //       .addCase(getAddressByUserId.pending, (state) => {
  //         state.loader = true;
  //       })
  //       .addCase(getAddressByUserId.fulfilled, (state, action) => {
  //         state.getAddress = action.payload?.data;
  //         state.loader = false;
  //       })
  //       .addCase(getAddressByUserId.rejected, (state) => {
  //         state.loader = false;
  //       })
  // .addCase(ssoLogin.fulfilled, (state, action) => {
  //   state.loading = false;
  //   localStorage.setItem("userId", action.payload?.user?.id);
  //   localStorage.setItem("token", action.payload?.user?.Token);
  //   state.userData = action.payload?.user;
  // });
  // .addCase(userLogin.fulfilled, (state, action) => {
  //   state.loader = false;
  //   state.userFirstName = action?.payload?.username?.first_name;
  //   state.userLastName = action?.payload?.username?.last_name;
  // });
  // },
});

export default UserAuthSlice.reducer;

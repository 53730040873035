import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const MrButton = ({
  children,
  onClick,
  type,
  variant,
  height,
  fontSize,
  fontWeight,
  width,
  borderRadius,
  startIcon,
  endIcon,
  color,
  textDecoration,
  hoverEffect,
  disableRipple,
  textTransform,
}) => {
  return (
    <>
      <Button
        onClick={onClick}
        type={type ? type : "button"}
        variant={variant ? variant : "contained"}
        startIcon={startIcon}
        endIcon={endIcon}
        disableRipple={disableRipple ? disableRipple : false}
        sx={{
          height: height ? height : "3rem",
          fontSize: fontSize ? fontSize : "1rem",
          fontWeight: fontWeight ? fontWeight : "400",
          width: width ? width : "auto",
          borderRadius: borderRadius ? borderRadius : "0.625rem",
          textTransform: textTransform ? textTransform : "capitalize",
          color: color ? color : "primary",
          textDecoration: textDecoration ? textDecoration : "none",
          "&:hover": {
            background: hoverEffect,
            textDecoration: textDecoration ? textDecoration : "none",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
};

MrButton.propTypes = {
  children: PropTypes.node.isRequired,
  endIcon: PropTypes.any,
  type: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  color: PropTypes.string,
  startIcon: PropTypes.any,
  height: PropTypes.string,
  variant: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  disableRipple: PropTypes.bool,
  hoverEffect: PropTypes.string,
  borderRadius: PropTypes.string,
  textTransform: PropTypes.string,
  textDecoration: PropTypes.string,
};

export default MrButton;

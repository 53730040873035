import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import sideNavImg from "Assets/Company/sideNavImg.webp";
import heroSectionPic from "Assets/Images/heroSectionPic.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";
import { partnerShipProgram, whyPrunePartner } from "DummyData";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import whyPrunePartnerimg from "Assets/Images/whyPrunePartnerimg.png";
import { allInOne } from "DummyData";
import parnterProgram from "Assets/Images/parnterProgram.png";
import { useNavigate } from "react-router-dom";
import ModalPopUp from "Components/ModalPopUp";
import { useDispatch, useSelector } from "react-redux";
import {
  openPopup,
  closePopup,
} from "../../Redux/Features/interNationalSimSlice";
import MrButton from "Components/MrButton";
import toast from "react-hot-toast";
import { createPrunePartner } from "../../Redux/Features/prunePartnerSlice";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// TABS Styling

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: "2rem" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired, // Changed to number
  value: PropTypes.number.isRequired, // Changed to number
};

// a11yProps helper function
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PrunePartnerProgram = () => {
  const xs = useMediaQuery("(max-Width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Create a mapping for tab labels to indexes
  const tabMapping = {
    pruneForBusiness: 0,
    resellers: 1,
    apiPartners: 2,
    brandPartners: 3,
    affiliates: 4,
  };
  const [value, setValue] = useState(tabMapping.pruneForBusiness);

  const isPopupOpen = useSelector(
    (state) => state.isInterNationalSimSlice.isPopupOpen
  );
  const handleOpenPopup = () => dispatch(openPopup());
  const handleClosePopup = () => dispatch(closePopup());
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const [partnerForm, setPartnerForm] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyWebsite: "",
    companyAddress: "",
    city: "",
    phoneNumber: "",
    query: "",
  });

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    // Allow only alphabets for specific fields
    if (
      [
        "firstName",
        "lastName",
        "companyName",
        "companyAddress",
        "city",
      ].includes(name)
    ) {
      updatedValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabet characters (including digits and symbols)
    }

    // Allow only numbers for phone number
    if (name === "phoneNumber") {
      updatedValue = value.replace(/[^0-9]/g, "").slice(0, 10); // Remove non-numeric characters
    }

    setPartnerForm((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("partnership_type", selectedOption);
    formData.append("first_name", partnerForm?.firstName);
    formData.append("last_name", partnerForm?.lastName);
    formData.append("company_name", partnerForm?.companyName);
    formData.append("company_website", partnerForm?.companyWebsite);
    formData.append("company_address", partnerForm?.companyAddress);
    formData.append("city", partnerForm?.city);
    formData.append("phone_number", partnerForm?.phoneNumber);
    formData.append("description", partnerForm?.query);

    if (!selectedOption) {
      toast.error("Please select the Partnership Type");
    } else {
      dispatch(
        createPrunePartner({
          url: `${process.env.REACT_APP_URI}reseller/partner-program/`,
          data: formData,
        })
      ).then((res) => {
        if (res?.payload?.success) {
          toast.success(
            res?.payload?.message || "Your request has been submitted"
          );
          handleClosePopup();
          setSelectedOption("");
          setPartnerForm({
            firstName: "",
            lastName: "",
            companyName: "",
            companyWebsite: "",
            companyAddress: "",
            city: "",
            phoneNumber: "",
            query: "",
          });
        } else {
          toast.error("Enter a valid URL in company website");
        }
      });
    }
  };

  // useEffect(() => {
  //   // A helper to catch any ResizeObserver-related errors
  //   if (typeof ResizeObserver !== "undefined") {
  //     const resizeObserver = new ResizeObserver(() => {});
  //     resizeObserver.disconnect();
  //   }
  // }, []);

  return (
    <>
      <Box sx={{ px: "0rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: "0rem",
            height: "5rem",
            px: { xs: ".5rem", sm: "4rem", md: "4rem" },
            backgroundColor: "white.main",
            zIndex: 9,
          }}
        >
          <Box sx={{ height: "5rem", width: "5rem", pb: ".5rem" }}>
            <img
              src={sideNavImg}
              alt="No Preview"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "1rem", sm: "4rem", md: "4rem" },
            }}
          >
            <Button
              onClick={() => navigate("/login")}
              variant="text"
              sx={{
                height: "3.2rem",
                borderRadius: ".625rem",
                textTransform: "capitalize",
              }}
            >
              Login
            </Button>

            <Button
              onClick={() => handleOpenPopup()}
              variant="outlined"
              sx={{
                height: "3.2rem",
                borderRadius: ".625rem",
                textTransform: "initial",
                color: "#4a872c",
                // outline: "1px solid #4a872c",
                border: "1px solid #4a872c",
                "&:hover": {
                  border: "1px solid #4a872c",
                },
              }}
            >
              Become a prtner
            </Button>
          </Box>
        </Box>

        {/* Hero Section */}
        <Box
          sx={{
            height: { xs: "auto", sm: "29.42rem", md: "29.42rem" },
            // border: "1px solid blue",
            display: "flex",
            flexDirection: { xs: "column", sm: "row", md: "row" },
            justifyContent: "space-between",
            // gap: "3rem",
            px: { xs: ".5rem", sm: "4rem", md: "4rem" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "55%", md: "55%" },
              display: "flex",
              flexDirection: "column",
              gap: "1.7rem",
              justifyContent: "center",
              // border: "1px solid green",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={400}
              sx={{ fontSize: "1.5rem" }}
            >
              Prune Partner Program
            </Typography>
            <Typography
              variant="h2"
              fontWeight={600}
              sx={{ fontSize: "2.5rem", lineHeight: "4rem" }}
            >
              Powering Global Connections with Seamless Integrations
            </Typography>
            <Button
              onClick={() => handleOpenPopup()}
              endIcon={<ArrowForwardIcon />}
              variant="contained"
              sx={{
                color: "white.main",
                height: "3.7rem",
                borderRadius: ".625rem",
                backgroundColor: "#4a872c",
                width: "15.75rem",
                fontSize: "1rem",
                textTransform: "initial",
                fontWeight: 400,
              }}
            >
              Become a Partner
            </Button>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "45%", md: "45%" } }}>
            <Box sx={{ height: "100%", width: "100%" }}>
              <img
                src={heroSectionPic}
                alt="No Preview"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("Assets/Images/no-preview.jpeg");
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Explore our partnership solutions Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            px: { xs: ".5rem", sm: "4rem", md: "4rem" },
          }}
        >
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{
              fontSize: "2rem",
              color: "balck.main",
              textAlign: "center",
            }}
          >
            Explore our partnership solutions
          </Typography>

          <Box>
            <Box sx={{ minWidth: { xs: "280px" } }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  // border: "1px solid black",
                  backgroundColor: "#f4ffe4",
                  height: "6rem",
                  borderRadius: "0.9375rem",
                }}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  aria-label="Product Details"
                  TabIndicatorProps={{
                    style: { display: "none" },
                  }}
                  sx={{
                    "& button": {
                      borderRadius: ".625rem",
                      // border: "1.5px solid #db3a3e",
                      marginRight: "3rem", // default margin for all buttons
                      minHeight: "3.5rem",
                      padding: "0.75rem 1.5rem",
                      color: "black.main",
                      fontSize: "1rem",
                      fontWeight: 500,
                    },
                    "& button:last-of-type": {
                      marginRight: 0, // remove marginRight from the last button
                    },
                    "& button.Mui-selected": {
                      backgroundColor: "white.main",
                      color: "#4a872c",
                      transition: ".3s",
                      // fontWeight: 600,
                    },
                  }}
                >
                  <Tab
                    label="Prune for Business"
                    sx={{ textTransform: "capitalize" }}
                    {...a11yProps(tabMapping.pruneForBusiness)}
                  />
                  <Tab
                    label="Resellers"
                    sx={{ textTransform: "capitalize" }}
                    {...a11yProps(tabMapping.resellers)}
                  />
                  <Tab
                    label="API Partners"
                    sx={{ textTransform: "capitalize" }}
                    {...a11yProps(tabMapping.apiPartners)}
                  />
                  <Tab
                    label="Brand Partners"
                    sx={{ textTransform: "capitalize" }}
                    {...a11yProps(tabMapping.brandPartners)}
                  />
                  <Tab
                    label="Affiliates"
                    sx={{ textTransform: "capitalize" }}
                    {...a11yProps(tabMapping.affiliates)}
                  />
                </Tabs>
              </Box>
              {/* Tab Panels now have numeric indexes */}
              <CustomTabPanel value={value} index={tabMapping.pruneForBusiness}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row", md: "row" },
                    // border: "1px solid red",
                    height: { xs: "auto", sm: "30rem", md: "30rem" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "60%", md: "60%" },
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.25rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ fontSize: "1.25rem" }}
                    >
                      {partnerShipProgram?.at(0)?.heading || "-"}
                    </Typography>

                    {partnerShipProgram &&
                      partnerShipProgram?.at(0)?.subHeading?.length &&
                      partnerShipProgram?.at(0)?.subHeading?.map((ele, i) => (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: ".6rem",
                          }}
                        >
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "1.2rem",
                              color: "green",
                              mt: ".27rem",
                            }}
                          />
                          <Typography
                            variant="body"
                            fontWeight={400}
                            sx={{ fontSize: "1rem", lineHeight: "1.6rem" }}
                          >
                            {ele || "-"}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ width: { xs: "100%", sm: "40%", d: "40%" } }}>
                    <Box sx={{ height: "100%", width: "100%" }}>
                      <img
                        src={partnerShipProgram?.at(0)?.image}
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={tabMapping.resellers}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row", md: "row" },
                    // border: "1px solid red",
                    height: { xs: "auto", sm: "30rem", md: "30rem" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "60%", md: "60%" },
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.25rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ fontSize: "1.25rem" }}
                    >
                      {partnerShipProgram?.at(1)?.heading || "-"}
                    </Typography>

                    {partnerShipProgram &&
                      partnerShipProgram?.at(1)?.subHeading?.length &&
                      partnerShipProgram?.at(1)?.subHeading?.map((ele, i) => (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: ".6rem",
                          }}
                        >
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "1.2rem",
                              color: "green",
                              mt: ".27rem",
                            }}
                          />
                          <Typography
                            variant="body"
                            fontWeight={400}
                            sx={{ fontSize: "1rem", lineHeight: "1.6rem" }}
                          >
                            {ele || "-"}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ width: { xs: "100%", sm: "40%", md: "40%" } }}>
                    <Box sx={{ height: "100%", width: "100%" }}>
                      <img
                        src={partnerShipProgram?.at(1)?.image}
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={tabMapping.apiPartners}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row", md: "row" },
                    // border: "1px solid red",
                    height: { xs: "auto", sm: "30rem", md: "30rem" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "60%", md: "60%" },
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.25rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ fontSize: "1.25rem" }}
                    >
                      {partnerShipProgram?.at(2)?.heading || "-"}
                    </Typography>

                    {partnerShipProgram &&
                      partnerShipProgram?.at(2)?.subHeading?.length &&
                      partnerShipProgram?.at(2)?.subHeading?.map((ele, i) => (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: ".6rem",
                          }}
                        >
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "1.2rem",
                              color: "green",
                              mt: ".27rem",
                            }}
                          />
                          <Typography
                            variant="body"
                            fontWeight={400}
                            sx={{ fontSize: "1rem", lineHeight: "1.6rem" }}
                          >
                            {ele || "-"}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ width: { xs: "100%", sm: "40%", md: "40%" } }}>
                    <Box sx={{ height: "100%", width: "100%" }}>
                      <img
                        src={partnerShipProgram?.at(2)?.image}
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={tabMapping.brandPartners}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row", md: "row" },
                    // border: "1px solid red",
                    height: { xs: "auto", sm: "30rem", md: "30rem" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "60%", md: "60%" },
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.25rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ fontSize: "1.25rem" }}
                    >
                      {partnerShipProgram?.at(3)?.heading || "-"}
                    </Typography>

                    {partnerShipProgram &&
                      partnerShipProgram?.at(3)?.subHeading?.length &&
                      partnerShipProgram?.at(3)?.subHeading?.map((ele, i) => (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: ".6rem",
                          }}
                        >
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "1.2rem",
                              color: "green",
                              mt: ".27rem",
                            }}
                          />
                          <Typography
                            variant="body"
                            fontWeight={400}
                            sx={{ fontSize: "1rem", lineHeight: "1.6rem" }}
                          >
                            {ele || "-"}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ width: { xs: "100%", sm: "40%", md: "40%" } }}>
                    <Box sx={{ height: "100%", width: "100%" }}>
                      <img
                        src={partnerShipProgram?.at(3)?.image}
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={tabMapping.affiliates}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row", md: "row" },
                    // border: "1px solid red",
                    height: { xs: "auto", sm: "30rem", md: "30rem" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "60%", md: "60%" },
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.25rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ fontSize: "1.25rem" }}
                    >
                      {partnerShipProgram?.at(4)?.heading || "-"}
                    </Typography>

                    {partnerShipProgram &&
                      partnerShipProgram?.at(4)?.subHeading?.length &&
                      partnerShipProgram?.at(4)?.subHeading?.map((ele, i) => (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: ".6rem",
                          }}
                        >
                          <CheckCircleOutlineIcon
                            sx={{
                              fontSize: "1.2rem",
                              color: "green",
                              mt: ".27rem",
                            }}
                          />
                          <Typography
                            variant="body"
                            fontWeight={400}
                            sx={{ fontSize: "1rem", lineHeight: "1.6rem" }}
                          >
                            {ele || "-"}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ width: { xs: "100%", sm: "40%", md: "40%" } }}>
                    <Box sx={{ height: "100%", width: "100%" }}>
                      <img
                        src={partnerShipProgram?.at(4)?.image}
                        alt="No Preview"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("Assets/Images/no-preview.jpeg");
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
            </Box>
          </Box>
        </Box>

        {/* Why prune Section */}
        <Box
          sx={{
            height: { xs: "auto", sm: "35.5rem", md: "35.5rem" },
            width: "100%",
            backgroundImage: `url(${whyPrunePartnerimg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            py: "3rem",
          }}
        >
          <Typography
            variant="h3"
            fontWeight={600}
            sx={{
              fontSize: { xs: "2rem", sm: "3rem", md: "3rem" },
              color: "white.main",
              textAlign: "center",
            }}
          >
            Why Partner with Prune ?
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              px: { xs: ".5rem", sm: "4rem", md: "4rem" },
              mt: "2rem",
            }}
          >
            <Grid
              item
              xs={12}
              container
              spacing={{ xs: "2.5rem", sm: "0rem", md: "0rem" }}
            >
              {whyPrunePartner &&
                whyPrunePartner?.length &&
                whyPrunePartner?.map((ele, i) => (
                  <Grid key={i} item xs={6} md={3}>
                    <Box
                      sx={{
                        width: "100%",
                        // border: "1px solid red",
                        display: "flex",
                        // justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1.5rem",
                        mb: { xs: "1rem", sm: "5rem", md: "5rem" },
                      }}
                    >
                      <Box
                        sx={{
                          height: "4rem",
                          width: "4rem",
                          borderRadius: ".7rem",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={ele?.image}
                          alt="No Preview"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            e.onerror = null;
                            e.target.src = require("Assets/Images/no-preview.jpeg");
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{
                            fontSize: {
                              xs: "1rem",
                              sm: "1.2rem",
                              md: "1.2rem",
                            },
                            color: "white.main",
                            textAlign: "center",
                          }}
                        >
                          {ele?.heading || "-"}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={600}
                          sx={{
                            fontSize: {
                              xs: "1rem",
                              sm: "1.2rem",
                              md: "1.2rem",
                            },
                            color: "white.main",
                            textAlign: "center",
                          }}
                        >
                          {ele?.subHeading || "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>

        {/* All in one tool  */}
        <Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              px: { xs: ".5rem", sm: "4rem", md: "4rem" },
              mt: "2rem",
            }}
          >
            <Grid item xs={12} container spacing={6}>
              {allInOne &&
                allInOne?.length &&
                allInOne?.map((ele, i) => (
                  <Grid key={i} item md={6}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: { xs: "0rem", sm: "2rem", md: "2rem" },
                        mb: { xs: "0rem", sm: "5rem", md: "5rem" },
                        border: "2px solid #d8d9db",
                        p: { xs: "1rem", sm: "2rem", md: "2rem" },
                        borderRadius: "1rem",
                        height: { xs: "auto", sm: "30rem", md: "30rem" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: ".75rem",
                          // alignItems: "center",
                          // border: "2px solid green",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          sx={{ fontSize: "1.5rem", color: "black.main" }}
                        >
                          {ele?.heading || "-"}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{
                            fontSize: ".875rem",
                            color: "secondaryText.main",
                          }}
                        >
                          {ele?.subHeading || "-"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          height: "20rem",
                          width: "100%",
                          borderRadius: ".7rem",
                          overflow: "hidden",
                          // border: "2px solid red",
                        }}
                      >
                        <img
                          src={ele?.image}
                          alt="No Preview"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          onError={(e) => {
                            e.onerror = null;
                            e.target.src = require("Assets/Images/no-preview.jpeg");
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>

        {/* how do Ipartner of prune */}
        <Box
          sx={{
            px: { xs: ".5rem", sm: "4rem", md: "4rem" },
            mt: { xs: "1.5rem", sm: "0rem", md: "0rem" },
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            sx={{
              fontSize: { xs: "1.7rem", sm: "2rem", md: "2rem" },
              color: "black.main",
              textAlign: "center",
            }}
          >
            How do I partner with prune?
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", sm: "row", md: "row" },
              justifyContent: "space-between",
              height: { xs: "auto", sm: "28.25rem", md: "28.25rem" },
              mt: "3rem",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "60%", md: "60%" },
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5rem",
                  // width: "60%",
                  justifyContent: "center",
                }}
              >
                {[
                  "Choose your partnership",
                  "Complete your application",
                  "Our team will reach out to you for onboarding",
                ]?.map((ele, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#e7ffc3",
                        p: "1.5rem",
                        borderRadius: ".5rem",
                        height: "4rem",
                        width: "4rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#4a872c",
                        fontWeight: 500,
                        fontSize: "1.75rem",
                      }}
                    >
                      {i + 1}
                    </Box>
                    <Typography
                      variant="body"
                      fontWeight={500}
                      sx={{ fontSize: "1.25rem" }}
                    >
                      {ele || "-"}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Button
                onClick={() => handleOpenPopup()}
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                sx={{
                  height: "3.5rem",
                  width: "15rem",
                  borderRadius: ".625rem",
                  textTransform: "initial",
                  color: "white.main",
                  backgroundColor: "#4a872c",
                  border: "1px solid #4a872c",
                  "&:hover": {
                    // border: "1px solid #4a872c",
                    backgroundColor: "#4a872c",
                  },
                }}
              >
                Become a prtner
              </Button>
            </Box>

            <Box sx={{ width: { xs: "100%", sm: "40%", md: "40%" } }}>
              <Box sx={{ height: "100%", width: "100%" }}>
                <img
                  src={parnterProgram}
                  alt="No Preview"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("Assets/Images/no-preview.jpeg");
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Ready to partner section */}
        <Box
          sx={{
            px: { xs: ".5rem", sm: "4rem", md: "4rem" },
            height: { xs: "auto", sm: "25rem", md: "25rem" },
            my: "4rem",
          }}
        >
          <Box
            sx={{
              height: "100%",
              background:
                "linear-gradient(279deg, #853499 3.03%, #E99343 100%)",
              borderRadius: "1.875rem",
              p: { xs: "2rem 1rem", sm: "4rem 5rem", md: "4rem 5rem" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
              gap: { xs: "1.5rem", sm: "0rem", md: "0rem" },
            }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              sx={{
                fontSize: { xs: "2rem", sm: "3rem", md: "3rem" },
                color: "white.main",
              }}
            >
              Ready to Partner with Us?
            </Typography>
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{
                fontSize: "1.1rem",
                color: "white.main",
                textAlign: "center",
              }}
            >
              Join Prune&apos;s Partner Program today and transform travel
              connectivity. Offer your customers innovative eSIM & Int&apos;l
              SIM card and international SIM solutions, along with seamless bill
              payment services. Start earning competitive commissions and access
              exclusive partner resources. Let&apos;s connect the world
              together!
            </Typography>

            <Button
              onClick={() => handleOpenPopup()}
              endIcon={<ArrowForwardIcon />}
              variant="outlined"
              sx={{
                height: "4.2rem",
                width: { xs: "100%", sm: "24rem", md: "24rem" },
                borderRadius: ".625rem",
                textTransform: "capitalize",
                color: "#4a872c",
                fontSize: "1rem",
                backgroundColor: "white.main",
                // border: "1px solid #4a872c",
                "&:hover": {
                  border: "1px solid #fff",
                  backgroundColor: "white.main",
                },
              }}
            >
              Join Our Partner Program
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* Footer section */}
        <Box
          sx={{
            height: { xs: "auto", sm: "7.9rem", md: "7.9rem" },
            display: "flex",
            flexDirection: { xs: "column", sm: "row", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: "1rem", sm: "2rem", md: "2rem" },
          }}
        >
          <Box
            sx={{
              height: { xs: "8rem", sm: "6rem", md: "6rem" },
              width: { xs: "8rem", sm: "6rem", md: "6rem" },
              pb: ".5rem",
            }}
          >
            <img
              src={sideNavImg}
              alt="No Preview"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              py: "2rem",
              mr: { xs: "0rem", sm: "-12rem", md: "-12rem" },
            }}
          >
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{
                fontSize: ".875rem",
                color: "black.main",
                textAlign: "center",
              }}
            >
              Prune Partners is part of the Prune Organizations
            </Typography>
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{
                fontSize: ".875rem",
                color: "#391442",
                textAlign: "center",
              }}
            >
              &#169; 2024 Prune . All right reserved
            </Typography>
          </Box>

          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "3rem",
              py: "2rem",
              alignItems: "end",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{
                fontSize: ".875rem",
                color: "black.main",
                // textAlign: "center",
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{
                fontSize: ".875rem",
                color: "black.main",
                // textAlign: "center",
              }}
            >
              Terms & Conditions
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Form modal */}
      <ModalPopUp
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={
          xs
            ? "Thank you for interest"
            : sm
            ? "Thank you for showing interest in partnering with us"
            : "Thank you for showing interest in partnering with us"
        }
        width={"53.8rem"}
        height={"90%"}
        showHeader={true}
        justifyContent={"center"}
        p={xs ? "1rem" : sm ? "2rem" : "2rem"}
      >
        <Typography
          variant="body"
          fontWeight={400}
          sx={{
            fontSize: "1rem",
            textAlign: "center",
            display: "block",
            mt: "-1rem",
            mb: "1.5rem",
          }}
        >
          Please share some information below, so our team can get in touch with
          you soon
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box>
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ fontSize: "1.25rem", mb: "-1rem" }}
            >
              Partnership Type
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                justifyContent: "space-between",
                mt: "1rem",
                mb: "1rem",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOption === "business"}
                    onChange={handleCheckboxChange}
                    value="business"
                    inputProps={{ "aria-label": "For Business" }}
                  />
                }
                label="For Business"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOption === "reseller"}
                    onChange={handleCheckboxChange}
                    value="reseller"
                    inputProps={{ "aria-label": "Reseller" }}
                  />
                }
                label="Reseller"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOption === "api_partners"}
                    onChange={handleCheckboxChange}
                    value="api_partners"
                    inputProps={{ "aria-label": "API Partners" }}
                  />
                }
                label="API Partners"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOption === "brand_partners"}
                    onChange={handleCheckboxChange}
                    value="brand_partners"
                    inputProps={{ "aria-label": "Brand Partners" }}
                  />
                }
                label="Brand Partners"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOption === "affiliate"}
                    onChange={handleCheckboxChange}
                    value="affiliate"
                    inputProps={{ "aria-label": "Affiliate" }}
                  />
                }
                label="Affiliate"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "1rem", sm: "1.5rem", md: "1.5rem" },
            }}
          >
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ fontSize: "1.25rem", mb: "-1rem" }}
            >
              Contact Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                gap: { xs: "1rem", sm: "1.5rem", md: "1.5rem" },
                // border: "1px solid red",
              }}
            >
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="First Name"
                name="firstName"
                value={partnerForm?.firstName}
                onChange={handleChangeForm}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
              />
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Last Name"
                name="lastName"
                value={partnerForm?.lastName}
                onChange={handleChangeForm}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                gap: { xs: "1rem", sm: "1.5rem", md: "1.5rem" },
              }}
            >
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Company name"
                name="companyName"
                value={partnerForm?.companyName}
                onChange={handleChangeForm}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
              />
              <TextField
                fullWidth
                // required
                id="outlined-required"
                label="Company Website"
                name="companyWebsite"
                value={partnerForm?.companyWebsite}
                onChange={handleChangeForm}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                gap: { xs: "1rem", sm: "1.5rem", md: "1.5rem" },
              }}
            >
              <TextField
                fullWidth
                // required
                id="outlined-required"
                label="Company Address"
                name="companyAddress"
                value={partnerForm?.companyAddress}
                onChange={handleChangeForm}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
              />
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="City"
                name="city"
                value={partnerForm?.city}
                onChange={handleChangeForm}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Phone number"
                name="phoneNumber"
                value={partnerForm?.phoneNumber}
                onChange={handleChangeForm}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    height: "3.125rem",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
              <TextField
                fullWidth
                // required
                id="outlined-required"
                label="Tell us more about how would you like to work together"
                multiline
                minRows={7.5}
                maxRows={7.5}
                name="query"
                value={partnerForm?.query}
                onChange={handleChangeForm}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".625rem",
                    mt: ".17rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                    overflow: "hidden", // Prevent resize triggering excessive events
                  },
                  "& .MuiInputLabel-shrink": {
                    mt: ".2rem",
                  },
                  height: "auto",
                  resize: "none", // Prevent textarea resizing
                }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <MrButton
                type={"submit"}
                endIcon={<ArrowForwardIcon />}
                height={"3.5rem"}
                width={"21.5rem"}
              >
                Submit
              </MrButton>
            </Box>
          </Box>
        </form>
      </ModalPopUp>
    </>
  );
};

export default PrunePartnerProgram;

import { createSlice } from "@reduxjs/toolkit";

const emailVerifySlice = createSlice({
  name: "emailVerifyPop",
  initialState: {
    isemailVerifyPop: false,
  },
  reducers: {
    openemaiverifypop: (state) => {
      state.isemailVerifyPop = true;
    },
    closeemailverifypop: (state) => {
      state.isemailVerifyPop = false;
    },
  },
});

export const { openemaiverifypop, closeemailverifypop } = emailVerifySlice.actions;
export default emailVerifySlice.reducer;

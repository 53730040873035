import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getWalletBalance = createAsyncThunk(
  "getWalletBalance",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          // authorization: `Basic ${basicAuth}`,
          // "Access-Control-Allow-Origin": "*",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

const walletSlice = createSlice({
  name: "walletSlice",
  initialState: {
    loading: false,
    walletBalance: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getWalletBalance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWalletBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.walletBalance = action?.payload;
      })
      .addCase(getWalletBalance.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default walletSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const takeOrderDetails = createAsyncThunk(
  "takeOrderDetails",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const physicalSimOrder = createAsyncThunk(
  "physicalSimOrder",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const esimOrderSlice = createSlice({
  name: "esimOrderSlice",
  initialState: {
    loading: false,
  },

  reducers: {},
});

export default esimOrderSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const mobileSidNavSlice = createSlice({
  name: "mobileSidNavSlice",
  initialState: {
    isSidenav: false,
  },
  reducers: {
    openMobileNav: (state) => {
      state.isSidenav = true;
    },
    closeMobileNav: (state) => {
      state.isSidenav = false;
    },
  },
});

export const { openMobileNav, closeMobileNav } = mobileSidNavSlice.actions;
export default mobileSidNavSlice.reducer;

import React, { useState } from "react";
import toast from "react-hot-toast";
import OTPInput from "otp-input-react";
import { LoadingButton } from "@mui/lab";
import Avatar from "@mui/material/Avatar";
import { useDispatch } from "react-redux";
import MrButton from "Components/MrButton";
import { getFirstCharacters } from "Utils";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { userLogin } from "../../Redux/Features/UserAuthSlice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoginPageHeding from "../../Components/LoginHeading/index";
import LoginPageImage from "../../Components/LoginPageImage/index";

const LoginEmaiVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = localStorage.getItem("userName");
  const userEmail = localStorage.getItem("email");

  const maskEmail = (email) => {
    if (!email) return "";

    const [localPart, domain] = email.split("@") || [];
    if (!localPart || !domain) return "";

    const visiblePartLength = Math.min(2, localPart.length);
    return `${localPart.slice(0, visiblePartLength)}${"*".repeat(
      localPart.length - visiblePartLength
    )}@${domain}`;
  };

  const maskedEmail = maskEmail(userEmail);

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    setLoading(true);

    dispatch(
      userLogin({
        url: `${process.env.REACT_APP_URI}reseller/login/`,
        data: {
          step: "verify_otp",
          username: userName,
          otp: otp,
        },
      })
    ).then((res) => {
      setLoading(false);
      if (res?.payload?.message === "OTP verified." && otp?.length === 6) {
        toast.success("OTP verified successfully");
        navigate("/reset_password");
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: { xs: "none", sm: "block", md: "block" },
          }}
        >
          <LoginPageImage flexDirection={"column-reverse"} />
        </Box>

        <Box
          sx={{
            height: "100%",
            width: { xs: "100%", sm: "50%", md: "50%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            px: { xs: "1rem", sm: "8.8rem", md: "8.8rem" },
          }}
        >
          <LoginPageHeding />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mt: "2rem",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#00C471",
                height: "3rem",
                width: "3rem",
                mr: ".75rem",
              }}
            >
              {getFirstCharacters(userName)}
            </Avatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
                mb: "1.5rem",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={600}
                sx={{ fontSize: "1rem" }}
              >
                {userName || "-"}
              </Typography>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ fontSize: "0.75rem" }}
              >
                Prune
              </Typography>
            </Box>
          </Box>
          <Box mb={0.5}>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{
                fontSize: ".9rem",
                width: "100%",
                mb: ".75rem",
              }}
            >
              Verify your account by entering OTP sent to {maskedEmail || "-"}
            </Typography>
          </Box>
          <form action="#" onSubmit={handleChange} style={{ width: "100%" }}>
            <Box>
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{ fontSize: "1.25rem", mb: ".5rem" }}
              >
                Enter OTP to Verify
              </Typography>

              <OTPInput
                required
                className="display-flex"
                inputClassName="otpVerification"
                // secure
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={6}
                otpType="number"
                type="text"
                disabled={false}
              />
            </Box>

            {loading ? (
              <LoadingButton
                sx={{
                  mt: 3,
                  height: "3rem",
                  borderRadius: "0.625rem",
                  fontSize: "3rem",
                  width: "100%",
                  "&.MuiLoadingButton-icon": {
                    fontSize: "3rem",
                  },
                }}
                color="secondary"
                loading={loading}
                variant="contained"
              ></LoadingButton>
            ) : (
              <Box mt={3}>
                <MrButton
                  type={"submit"}
                  fontSize={"1rem"}
                  width={"100%"}
                  textTransform={"none"}
                  height={"3rem"}
                  borderRadius={"0.625rem"}
                  fontWeight={"300"}
                  variant={"contained"}
                  endIcon={<ArrowForwardIcon />}
                >
                  Verify
                </MrButton>
              </Box>
            )}
          </form>
        </Box>
      </Box>
    </>
  );
};

export default LoginEmaiVerify;

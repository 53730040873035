import { combineReducers, configureStore } from "@reduxjs/toolkit";
import homeSlice from "../Features/homeSlice";
import countryPlansSlice from "../Features/countryPlansSlice";
import interNationalSimSlice from "../Features/interNationalSimSlice";
import emailVerifySlice from "../Features/emailVerifySlice";
import mobileSidNavSlice from "../Features/mobileSidNavSlice";
import UserAuthSlice from "../Features/UserAuthSlice";
import walletSlice from "../Features/walletSlice";
import orderSlice from "../Features/orderSlice";
import transactionSlice from "../Features/transactionSlice";
import plandetails from "../Features/plandetails";
import internationalSlice from "../Features/internationalSlice";
import analyticsSlice from "../Features/analyticsSlice";
import esimOrderSlice from "../Features/esimOrderSlice";
import prunePartnerSlice from "../Features/prunePartnerSlice";
import stockSlice from "../Features/stockSlice";

const rootReducer = combineReducers({
  isUserAuthSlice: UserAuthSlice,
  isHomeSlice: homeSlice,
  isCountryPlansSlice: countryPlansSlice,
  isInterNationalSimSlice: interNationalSimSlice,
  isEmailVerifySlice: emailVerifySlice,
  isMobileSidNavSlice: mobileSidNavSlice,
  isWalletSlice: walletSlice,
  isOrderSlice: orderSlice,
  isTransactionSlice: transactionSlice,
  isPlandetails: plandetails,
  isInternationalSlice: internationalSlice,
  isAnalyticsSlice: analyticsSlice,
  isEsimOrderSlice: esimOrderSlice,
  isPrunePartnerSlice: prunePartnerSlice,
  isStockSlice: stockSlice,
});

const Store = configureStore({
  reducer: rootReducer,
  // devTools: process.env.REACT_APP_API !== "production",
});

export { Store };

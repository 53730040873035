import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import toast from "react-hot-toast";
import UploadIcon from "Assets/Images/UploadIcon.webp";
import { FileUploader } from "react-drag-drop-files";
import MrButton from "Components/MrButton";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";

const fileTypes = ["JPG", "PNG", "GIF"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: ".625rem",
  // border: '2px solid #000',
  boxShadow: 10,
  p: 2,
};

const AddPartnerPage = () => {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState("companyDetails");
  const [formData, setFormData] = useState({
    companyName: "",
    cinNo: "",
    phoneNumber: "",
    emailId: "",
    companyAddress: "",
    // panCardNumber: "",
    bankName: "",
    bankAccountNumber: "",
    ifscCode: "",

    agentName: "",
    agentPhoneNumber: "",
    agentState: "",
    agentPincode: "",
    agentUserName: "",
    agentEmailId: "",
    agentPassword: "",
    agentRePassword: "",

    transferWalletBalance: "",
    transferStock: "",
  });

  const [uploadGstFile, setUploadGstFile] = useState(null);
  // const [resetUploader, setResetUploader] = useState(false);
  const handleUploadGstImg = (file) => {
    setUploadGstFile(file);
  };

  const [uploadPancard, setUploadPancard] = useState(null);
  const handleUploadPancard = (file) => {
    setUploadPancard(file);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateFields = () => {
    console.log(formData); // Debug: Check formData values

    if (tabs === "companyDetails") {
      if (
        !formData?.companyName ||
        !formData?.cinNo ||
        !formData?.phoneNumber ||
        !formData?.emailId ||
        !formData?.companyAddress ||
        // !formData?.panCardNumber ||
        !formData?.bankName ||
        !formData?.bankAccountNumber ||
        !formData?.ifscCode
      ) {
        toast.error("Please fill in all fields in Company Details.");
        return false;
      }
    } else if (tabs === "agentDetails") {
      if (
        !formData?.agentName ||
        !formData?.agentPhoneNumber ||
        !formData?.agentState ||
        !formData?.agentPincode ||
        !formData?.agentUserName ||
        !formData?.agentEmailId ||
        !formData?.agentPassword ||
        !formData?.agentRePassword ||
        !uploadGstFile ||
        !uploadPancard
      ) {
        toast.error("Please fill in all fields in Agent Details.");
        return false;
      }

      if (formData?.agentPassword !== formData?.agentRePassword) {
        toast.error("Passwords do not match.");
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateFields()) {
      if (tabs === "companyDetails") {
        setTabs("agentDetails");
      } else if (tabs === "agentDetails") {
        setTabs("assignAssets");
      }
    }
  };

  const tabData = [
    { key: "companyDetails", label: "Company Details" },
    { key: "agentDetails", label: "Agent Details" },
    // { key: "documentsRequired", label: "Documents Required" },
    // { key: "setupAccount", label: "Setup Account" },
    { key: "assignAssets", label: "Assign Assets" },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h5"
            fontWeight={600}
            sx={{
              fontSize: "1.2rem",
            }}
          >
            Add New Agent
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "4rem",
          }}
        >
          {tabData?.map((tab) => (
            <Typography
              onClick={() => setTabs(tab?.key)}
              key={tab?.key}
              variant="body2"
              fontWeight={400}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3.5rem",
                width: "100%",
                borderRadius: ".625rem",
                cursor: "pointer",
                transition: "all 0.3s ease",
                // transform: tabs === tab?.key ? "scale(1.05)" : "scale(1)",
                color: tabs === tab?.key ? "primary.main" : "#000",
                backgroundColor: tabs === tab?.key ? "#fde9ff" : "#fafaff",
              }}
            >
              {tab?.label || "-"}
            </Typography>
          ))}
        </Box>

        <form onSubmit={handleSubmit}>
          <Box>
            {/* Confirmation Button */}
            <Box
              sx={{
                position: "fixed",
                left: "15.625rem",
                zIndex: 10,
                right: 0,
                bottom: 0,
                backgroundColor: "white.main",
                height: "5rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // border: "1px solid blue",
                gap: "5rem",
              }}
            >
              <Button
                variant="text"
                sx={{
                  height: "3rem",
                  borderRadius: ".625rem",
                  width: "10rem",
                  textTransform: "capitalize",
                  fontSize: "1rem",
                  fontWeight: 300,
                }}
                onClick={() => {
                  if (tabs === "companyDetails") {
                    // Add cancel logic if necessary
                    navigate(-1);
                  } else {
                    // Navigate back to the previous tab
                    if (tabs === "agentDetails") setTabs("companyDetails");
                    else if (tabs === "assignAssets") setTabs("agentDetails");
                    // else if (tabs === "setupAccount")
                    //   setTabs("documentsRequired");
                    // else if (tabs === "assignAssets") setTabs("setupAccount");
                  }
                }}
              >
                {tabs === "companyDetails" ? "Cancel" : "Back"}
              </Button>

              <Button
                type={
                  tabs === "companyDetails" || tabs === "agentDetails"
                    ? "button"
                    : "submit"
                }
                // type="submit"
                onClick={() => {
                  if (tabs === "companyDetails") {
                    setTabs("agentDetails");
                  } else if (tabs === "agentDetails") {
                    setTabs("assignAssets");
                  }
                }}
                variant="contained"
                sx={{
                  height: "3rem",
                  borderRadius: ".625rem",
                  width: "10rem",
                  textTransform: "capitalize",
                  fontSize: "1rem",
                  fontWeight: 300,
                }}
                endIcon={<ArrowForwardIcon />}
              >
                {/* Continue */}
                {tabs === "companyDetails" || tabs === "agentDetails"
                  ? "Continue"
                  : "Submit"}
              </Button>
            </Box>

            {/* Render Tab Content */}
            {tabs === "companyDetails" ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Company Details
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    // border: "1px solid red",
                  }}
                >
                  <Grid container spacing={6}>
                    <Grid item md={4}>
                      <FormControl sx={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{ fontSize: ".9rem", mb: ".5rem" }}
                        >
                          Company Name
                        </Typography>
                        <TextField
                          required
                          type="text"
                          size="small"
                          name="companyName"
                          value={formData.companyName}
                          onChange={handleChange}
                          sx={{
                            "& .MuiInputBase-root": {
                              width: "100%",
                              height: "3rem",
                              borderRadius: "0.625rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl sx={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{ fontSize: ".9rem", mb: ".5rem" }}
                        >
                          CIN No
                        </Typography>
                        <TextField
                          required
                          type="text"
                          size="small"
                          name="cinNo"
                          value={formData.cinNo}
                          onChange={handleChange}
                          sx={{
                            "& .MuiInputBase-root": {
                              width: "100%",
                              height: "3rem",
                              borderRadius: "0.625rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl sx={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{ fontSize: ".9rem", mb: ".5rem" }}
                        >
                          Phone Number
                        </Typography>
                        <TextField
                          required
                          type="text"
                          size="small"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          sx={{
                            "& .MuiInputBase-root": {
                              width: "100%",
                              height: "3rem",
                              borderRadius: "0.625rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box mt={"1rem"}>
                    <Grid container spacing={6}>
                      <Grid item md={4}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Email ID
                          </Typography>
                          <TextField
                            required
                            type="email"
                            size="small"
                            name="emailId"
                            value={formData.emailId}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={8}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Company Address
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="companyAddress"
                            value={formData.companyAddress}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />

                  <Box>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      sx={{ fontSize: "1.2rem", mb: "1rem" }}
                    >
                      Account Details
                    </Typography>

                    <Grid item xs={12} container spacing={6}>
                      {/* <Grid item md={4}>
                       <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Company Formation Documents
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="panCardNumber"
                            value={formData.panCardNumber}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid> */}
                      <Grid item md={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Bank Name
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="bankName"
                            value={formData.bankName}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Bank Account Number
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="bankAccountNumber"
                            value={formData.bankAccountNumber}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box mb={"8rem"}>
                      <Grid item xs={12} container spacing={6}>
                        <Grid item md={6}>
                          <FormControl sx={{ width: "100%", mt: "2rem" }}>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              sx={{ fontSize: ".9rem", mb: ".5rem" }}
                            >
                              IFSC/SWIFT Code
                            </Typography>
                            <TextField
                              required
                              type="text"
                              size="small"
                              name="ifscCode"
                              value={formData.ifscCode}
                              onChange={handleChange}
                              sx={{
                                "& .MuiInputBase-root": {
                                  width: "100%",
                                  height: "3rem",
                                  borderRadius: "0.625rem",
                                },
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : tabs === "agentDetails" ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Agent Details
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    // border: "1px solid red",
                  }}
                >
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{ fontSize: ".9rem", mb: ".5rem" }}
                        >
                          Agent Name
                        </Typography>
                        <TextField
                          required
                          type="text"
                          size="small"
                          name="agentName"
                          value={formData?.agentName}
                          onChange={handleChange}
                          sx={{
                            "& .MuiInputBase-root": {
                              width: "100%",
                              height: "3rem",
                              borderRadius: "0.625rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{ fontSize: ".9rem", mb: ".5rem" }}
                        >
                          Phone Number
                        </Typography>
                        <TextField
                          required
                          type="text"
                          size="small"
                          name="agentPhoneNumber"
                          value={formData?.agentPhoneNumber}
                          onChange={handleChange}
                          sx={{
                            "& .MuiInputBase-root": {
                              width: "100%",
                              height: "3rem",
                              borderRadius: "0.625rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box mt={"1rem"}>
                    <Grid container spacing={6}>
                      <Grid item md={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Region/State
                          </Typography>
                          <TextField
                            required
                            type="email"
                            size="small"
                            name="agentState"
                            value={formData?.agentState}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Pincode
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="agentPincode"
                            value={formData?.agentPincode}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />

                  <Typography
                    variant="h6"
                    fontWeight={600}
                    sx={{ fontSize: "1.2rem" }}
                  >
                    Login Details
                  </Typography>

                  <Box>
                    <Grid container spacing={6}>
                      <Grid item md={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            User name
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="agentUserName"
                            value={formData?.agentUserName}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Email Address
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="agentEmailId"
                            value={formData?.agentEmailId}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item mt={"-1rem"} md={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Password
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="agentPassword"
                            value={formData?.agentPassword}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item mt={"-1rem"} md={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            sx={{ fontSize: ".9rem", mb: ".5rem" }}
                          >
                            Re-enter Password
                          </Typography>
                          <TextField
                            required
                            type="text"
                            size="small"
                            name="agentRePassword"
                            value={formData?.agentRePassword}
                            onChange={handleChange}
                            sx={{
                              "& .MuiInputBase-root": {
                                width: "100%",
                                height: "3rem",
                                borderRadius: "0.625rem",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />

                  <Box mb={"8rem"}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      sx={{ fontSize: "1.2rem", mb: "1rem" }}
                    >
                      Upload Documents
                    </Typography>
                    {/* Image section  */}
                    <Box
                      sx={{
                        // border: "1px solid black",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: "3rem",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: ".5rem",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{ fontSize: ".9rem" }}
                        >
                          Upload Tax Document
                        </Typography>
                        <Box sx={{ position: "relative" }}>
                          <Box
                            sx={{
                              height: "3rem",
                              width: "3rem",
                              cursor: "pointer",
                              position: "absolute",
                              top: "25%",
                              left: "50%",
                              transform: "translate(-50%,0%)",
                            }}
                          >
                            <img
                              src={UploadIcon}
                              alt="No Preview"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              onError={(e) => {
                                e.onerror = null;
                                e.target.src = require("Assets/Images/no-preview.jpeg");
                              }}
                            />
                          </Box>
                          <FileUploader
                            // key={resetUploader}
                            handleChange={handleUploadGstImg}
                            name="file"
                            types={fileTypes}
                            value={uploadGstFile}
                            height={"7rem"}
                            label="Drag & drop file here or click to browse"
                            classes="upload_gst_drope_zone"
                            dropMessageStyle={{
                              backgroundColor: "#f589ff",
                            }}
                            required
                          />
                        </Box>
                        {uploadGstFile ? (
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "0.7rem", color: "#666666" }}
                          >
                            {uploadGstFile?.name}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: ".5rem",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{ fontSize: ".9rem" }}
                        >
                          Company Formation Document
                        </Typography>
                        <Box sx={{ position: "relative" }}>
                          <Box
                            sx={{
                              height: "3rem",
                              width: "3rem",
                              cursor: "pointer",
                              position: "absolute",
                              top: "25%",
                              left: "50%",
                              transform: "translate(-50%,0%)",
                            }}
                          >
                            <img
                              src={UploadIcon}
                              alt="No Preview"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              onError={(e) => {
                                e.onerror = null;
                                e.target.src = require("Assets/Images/no-preview.jpeg");
                              }}
                            />
                          </Box>
                          <FileUploader
                            // key={resetUploader}
                            handleChange={handleUploadPancard}
                            name="file"
                            types={fileTypes}
                            value={uploadPancard}
                            height={"7rem"}
                            label="Drag & drop file here or click to browse"
                            classes="upload_gst_drope_zone"
                            dropMessageStyle={{
                              backgroundColor: "#f589ff",
                            }}
                            required
                          />
                        </Box>
                        {uploadPancard ? (
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "0.7rem", color: "#666666" }}
                          >
                            {uploadPancard?.name}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Assign Assets
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      width: "50%",
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={500}
                      sx={{ fontSize: "1.2rem" }}
                    >
                      Assign Money to wallet
                    </Typography>
                    <Box>
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        sx={{ fontSize: "1rem", color: "secondaryText.main" }}
                      >
                        Available Balance:&nbsp;
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          sx={{ fontSize: "1rem", color: "success.main" }}
                        >
                          ₹ 54,0000
                        </Typography>
                      </Typography>

                      {formData.transferWalletBalance &&
                      formData.transferWalletBalance ? (
                        formData.transferWalletBalance
                      ) : (
                        <Box sx={{ mt: "2rem" }}>
                          <MrButton
                            onClick={handleOpen}
                            endIcon={<ArrowForwardIcon />}
                          >
                            Assing Wallet Balance
                          </MrButton>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="h5"
                      fontWeight={500}
                      sx={{ fontSize: "1.2rem" }}
                    >
                      Assign International SIM Stock
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </form>
      </Box>

      {/* Modal starts form here */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mb={"1rem"}
          >
            Add money to wallet
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
            <FormControl sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                fontWeight={400}
                sx={{ fontSize: ".9rem", mb: ".5rem" }}
              >
                Enter Amount
              </Typography>
              <TextField
                required
                type="text"
                size="small"
                name="transferWalletBalance"
                value={formData.transferWalletBalance}
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "100%",
                    height: "3rem",
                    borderRadius: "0.625rem",
                  },
                }}
              />
            </FormControl>

            <Typography
              variant="body2"
              fontWeight={400}
              sx={{ fontSize: "1rem", color: "success.main" }}
            >
              Available Balance:&nbsp;
              <Typography
                variant="caption"
                fontWeight={400}
                sx={{ fontSize: "1rem", color: "success.main" }}
              >
                ₹ 45,0000
              </Typography>
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                height: "2.5rem",
                alignItems: "center",
                backgroundColor: "#ffeed3",
                borderRadius: ".625rem",
                pl: "1rem",
              }}
            >
              <ReportProblemRoundedIcon sx={{ color: "#cc8007" }} />
              <Typography
                variant="body2"
                fontWeight={400}
                sx={{
                  fontSize: ".9rem",
                }}
              >
                Once confirmed, this action cannot be undone.
              </Typography>
            </Box>

            <MrButton
              onClick={() => {
                handleClose();
              }}
            >
              Confirm & Continue
            </MrButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddPartnerPage;

import React, { useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
  useMediaQuery,
} from "@mui/material";
import { analyticsData } from "DummyData";
import Map from "Assets/Images/Map.webp";
import { countryData } from "DummyData";
import { styled } from "@mui/material/styles";
import AnalyticsCard from "Components/Cards/AnalyticsCard";
import Calendar, { DateObject } from "react-multi-date-picker";
// import MrSkeleton from "Components/MrSkeleton";
import ReactApexChart from "react-apexcharts";

// Rating bars custom
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#96429e" : "#308fe8",
  },
}));

const SalesmanAnalyticsPage = () => {
  const xs = useMediaQuery("(max-Width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");
  const columnWidth = xs ? "10" : sm ? "25" : "25";
  const numberOfMonths = xs ? 1 : sm ? 2 : 2;

  const [values, setValues] = useState([
    new DateObject().setDay(10).subtract(1, "month"),
    new DateObject().setDay(new Date().getDate()).add("", "month"),
  ]);

  console.log(
    `${values?.at(0)?.day}/${values?.at(0)?.month?.number}/${
      values?.at(0)?.year
    }`,
    `${values?.at(1)?.day}/${values?.at(1)?.month?.number}/${
      values?.at(1)?.year
    }`,
    values,
    "Date Range"
  );

  // Graph Data Manage
  const [series] = useState([
    {
      name: "sales",
      data: [
        { x: "2024-02-21", y: 400 },
        { x: "2024-02-24", y: 450 },
        { x: "2024-02-27", y: 480 },
        { x: "2024-03-01", y: 375 },
        { x: "2024-03-04", y: 250 },
        { x: "2024-03-07", y: 580 },
        { x: "2024-03-10", y: 1000 },
        { x: "2024-03-13", y: 690 },
        { x: "2024-03-16", y: 200 },
        { x: "2024-03-19", y: 875 },
        { x: "2024-03-22", y: 600 },
        { x: "2024-03-25", y: 700 },
      ],
    },
  ]);

  const options = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
    plotOptions: {
      bar: {
        columnWidth: columnWidth,
      },
    },
    colors: ["#96429e"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      customLegendItems: ["Actual", "Expected"],
    },
    xaxis: {
      type: "category",
      categories: series[0].data.map((item) => {
        const date = new Date(item.x);
        const options = { day: "numeric", month: "short" };
        return date.toLocaleDateString("en-US", options);
      }),
      labels: {
        show: true,
      },
    },
    yaxis: {
      min: 0, // Ensure minimum value starts from 0
      tickAmount: 5, // Adjust the number of ticks based on your requirement
      labels: {
        formatter: function (val) {
          return "₹" + val.toFixed(0); // Ensure y-axis labels are formatted as whole numbers
        },
      },
    },
    grid: {
      show: true, // Hide grid lines
    },
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mb: "1.5rem",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            sx={{
              fontSize: "1.2rem",
            }}
          >
            Analytics
          </Typography>

          <Box>
            <Calendar
              style={{
                borderRadius: ".625rem",
                fontSize: "1rem",
                padding: "3px 10px",
                border: "1.5px solid #ccc",
                display: "flex",
                textAlign: "center",
                height: "3rem",
                width: "15rem",
              }}
              value={values}
              onChange={setValues}
              range
              rangeHover
              inputClass="custom-input"
              numberOfMonths={numberOfMonths}
              format="DD/MM/YYYY"
              showOtherDays
              maxDate={new Date()}
              dateSeparator=" to "
            />
          </Box>
        </Box>

        {/* <MrSkeleton props={"analytics"} /> */}

        <Box sx={{ display: "flex", width: "100%" }}>
          <Grid
            item
            xs={12}
            container
            spacing={{ xs: "1rem", sm: "2rem", md: "2rem" }}
          >
            {analyticsData &&
              analyticsData?.length > 0 &&
              analyticsData?.map((ele, i) => (
                <Grid key={i} item xs={12} sm={3} md={3}>
                  <AnalyticsCard ele={ele} i={i} />
                </Grid>
              ))}
          </Grid>
        </Box>

        {/* Graph Data */}
        <Box
          sx={{
            // border: "1px solid red",
            height: { xs: "15rem", sm: "27.86319rem", md: "27.86319rem" },
            width: { xs: "100%", sm: "82%", md: "82%" },
            mb: { xs: "2rem", sm: "4rem", md: "4rem" },
            mt: { xs: "2rem", sm: "2rem", md: "2rem" },
          }}
        >
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={"100%"}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "45%", md: "45%" },
            }}
          >
            <img
              src={Map}
              alt="No Preview"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "fill",
                mixBlendMode: "multiply",
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("Assets/Images/no-preview.jpeg");
              }}
            />
          </Box>

          <Box
            sx={{
              width: { xs: "100%", sm: "55%", md: "55%" },
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {countryData &&
              countryData?.length > 0 &&
              countryData?.map((ele, i) => (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      height: "1.5rem",
                      minWidth: "2rem",
                      mr: { xs: ".5rem", sm: "initial", md: "initial" },
                    }}
                  >
                    <img
                      src={ele?.image}
                      alt="No Preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                        mixBlendMode: "multiply",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("Assets/Images/no-preview.jpeg");
                      }}
                    />
                  </Box>

                  <Typography
                    variant="body1"
                    fontWeight={400}
                    sx={{
                      fontSize: "1rem",
                      minWidth: { xs: "7rem", sm: "auto", md: "auto" },
                    }}
                  >
                    {`${ele?.countryName || "-"} (${ele?.countryData || "-"}) `}
                  </Typography>

                  <Box sx={{ width: "70%" }}>
                    <BorderLinearProgress
                      sx={{ color: "red" }}
                      variant="determinate"
                      value={ele?.countryData || 0}
                    />
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SalesmanAnalyticsPage;

import { useEffect } from "react";
import "./App.css";

import { useLocation } from "react-router-dom";
import ProtectedRoutes from "Components/ProtectedRoutes";
import Layout from "Components/Layout";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.history.replaceState(null, "");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      <Layout>
        <ProtectedRoutes />
      </Layout>
    </>
  );
}

export default App;
